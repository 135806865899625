<template>
  <view-field :label="label">
    <router-link v-if="isLink" :to="link" :class="linkClasses">
      {{ textValue }}
    </router-link>
    <template v-else>{{ textValue }}</template>
  </view-field>
</template>

<script>
// Constants
import { CLIENTS_DETAILED } from '@/constants/routes';

// Components
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'ViewFieldClient',

  components: { ViewField },

  props: {
    label: { type: String, required: true },
    value: { type: Object, default: () => ({}) },
    linkClasses: { type: [String, Array, Object], default: '' },
  },

  computed: {
    isLink() {
      return !!(this.value?.firstName || this.value?.lastName);
    },
    textValue() {
      if (this.value?.firstName || this.value?.lastName) {
        return `${this.value.firstName || ''} ${this.value.lastName || ''}`;
      }
      return '—';
    },

    link() {
      return { name: CLIENTS_DETAILED, params: { id: this.value?.id } };
    },
  },
};
</script>
