<template>
  <div class="schema-form-field-list d-flex flex-column mb-10">
    <validation-observer v-slot="{ valid }">
      <FormTitle v-if="hasTitle" :label="label" :class="{ 'mb-0': isEmpty }" :remove-margins="isEmpty" />

      <div>
        <div
          v-for="(item, index) in value"
          :key="item[uniqueKey] || item[$options.UNIQUE_KEY] || index"
          class="d-flex align-start "
          :class="[index === 0 ? 'mt-2' : 'mt-10']"
        >
          <v-row>
            <v-col v-for="child in filteredChildrens" :key="child.prop" cols="12" md="6">
              <FormItem v-bind="child" :prop-path="getPropertyPath(child, index)" />
            </v-col>
          </v-row>

          <div v-if="!hasCounter" class="ml-2">
            <v-btn
              color="secondary"
              class="schema-form-field-list__button primary--text elevation-0"
              :class="{ 'schema-form-field-list__button--hide': !isMinFilled }"
              min-width="36"
              width="36"
              height="36"
              :disabled="!isMinFilled"
              @click="isMinFilled & deleteItem(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <v-btn
          class="primary--text elevation-0"
          :class="[isEmpty ? 'mt-0' : 'mt-5']"
          :disabled="isFilled || !valid"
          text
          @click="!isFilled && addItem()"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t(addButtonText) }}
        </v-btn>
      </div>
    </validation-observer>
  </div>
</template>

<script>
// Constants
import { ValidationObserver } from 'vee-validate';
import { LIST } from '@/constants/viewTypes';

// Models
import { UNIQUE_KEY } from '@/schemas/config';

// Components
import FormTitle from './FormTitle.vue';

export default {
  UNIQUE_KEY,

  name: 'SchemaFormFieldRequestFormList',

  inject: {
    addPropertyValueItem: 'addPropertyValueItem',
    deletePropertyValueItem: 'deletePropertyValueItem',
    filterSchema: 'filterSchema',
  },

  components: {
    FormTitle,
    FormItem: () => import('./FormItem.vue'),
    ValidationObserver,
  },

  inheritAttrs: false,

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    viewType: {
      type: String,
      default: LIST.default,
    },
    addButtonText: {
      type: String,
      default: 'button.add_inventory',
    },
  },

  computed: {
    filteredChildrens() {
      return this.filterSchema(this.children);
    },
    isFilled() {
      if (this.max === undefined || this.max === null || !this.value) {
        return false;
      }
      return this.value.length >= this.max;
    },

    isMinFilled() {
      if (this.min === undefined || this.min === null) {
        return true;
      }
      return this.value?.length > this.min;
    },

    hasCounter() {
      return this.viewType === LIST.counter;
    },

    isEmpty() {
      return this.value.length === 0;
    },

    hasTitle() {
      return !this.hasCounter && this.label;
    },
  },

  methods: {
    addItem() {
      this.addPropertyValueItem({
        propPath: this.propPath,
        schema: this.children,
      });
    },
    deleteItem(index) {
      this.deletePropertyValueItem({
        propPath: this.propPath,
        index,
      });
    },
    getPropertyPath(child, index) {
      return child.prop ? [...this.propPath, index, child.prop] : [...this.propPath, index];
    },
  },
};
</script>

<style lang="scss">
.schema-form-field-list {
  &__container {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__button {
    &--hide {
      opacity: 0;
    }
  }
}
</style>
