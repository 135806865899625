export default async function getUrlFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.addEventListener('load', event => {
      resolve(event.target.result);
    });

    fileReader.addEventListener('error', reject);
    fileReader.readAsDataURL(blob);
  });
}
