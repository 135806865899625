<template>
  <v-text-field class="text-field" v-bind="$attrs" v-on="$listeners">
    <template #message="{ message }">
      {{ message }}
    </template>

    <template #append>
      <slot name="append"></slot>
    </template>

    <template #prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'TextField',
};
</script>

<style lang="scss">
.text-field {
  &.v-input--is-disabled:not(.v-input--is-readonly) {
    pointer-events: unset !important;
  }

  .v-input__slot {
    padding-bottom: 6px !important;
    padding-left: 12px !important;
  }

  .v-input__control > .v-input__slot:before {
    border-color: rgba($--blue-color-5, 0.15) !important;
  }
}
</style>
