<template>
  <FormAutocomplete
    ref="autocomplete"
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    :item-text="item => `${item.firstName} ${item.lastName}`"
    :item-value="item => item"
    :compare-values="$options.compareById"
    :disabled="disabled"
    v-on="inheritedListeners"
  >
    <template v-if="showAppendItem" #append-item>
      <v-btn text color="primary" class="append-item" @click="$emit('event-from-field', 'add-client')">
        <v-icon class="me-4">mdi-plus</v-icon>
        <span v-if="media.isMobile">{{ $t('button.add') }}</span>
        <span v-else>{{ $t('button.add_client') }}</span>
      </v-btn>
    </template>
  </FormAutocomplete>
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

import { fetchClients, fetchCalendarClients } from '@/services/select';
import FormAutocomplete from './FormAutocomplete.vue';

export default {
  compareById,

  name: 'SchemaFormFieldClient',

  components: {
    FormAutocomplete,
  },

  inject: ['media'],

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAppendItem: {
      type: Boolean,
      default: false,
    },
    isCalendarSelect: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.autocomplete?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload) {
      const fetch = this.isCalendarSelect ? fetchCalendarClients : fetchClients;
      if (payload.search === '') {
        return fetch({ ...payload }).then(({ results }) => results);
      }
      return fetch({ ...payload, ...this.payload }).then(({ results }) => results);
    },
  },
};
</script>

<style lang="scss">
.append-item {
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 70px !important;
  background: #ffffff;
  border-top: 1px solid #d9d9d9;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .v-btn__content {
    justify-content: start;
  }
}
</style>
