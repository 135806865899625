import _debounce from 'lodash.debounce';
import _throttle from 'lodash.throttle';

// eslint-disable-next-line import/prefer-default-export
export function debounce(callback, wait, options) {
  return _debounce(callback, wait, options);
}

export function throttle(func, ms) {
  return _throttle(func, ms, {
    leading: false,
  });
}

export function delay(time) {
  const start = Date.now();

  return function delayInstance(cb) {
    setTimeout(() => {
      cb();
    }, Math.max(start - Date.now() + time, 0));
  };
}

export function retry(cb, interval = 1000) {
  async function retryInstance() {
    const result = await cb?.apply(this);

    if (result) {
      setTimeout(() => retryInstance?.apply(this), interval);
    }
  }

  return retryInstance;
}
