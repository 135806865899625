<template>
  <div class="form-flex-row">
    <div
      v-for="item in filteredChildrens"
      :key="item.prop"
      class="form-flex-row__item"
      :class="`form-flex-row__item-${item.prop}`"
    >
      <FormItem v-bind="item" :prop-path="getPropertyPath(item)">
        {{ item }}
      </FormItem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemaFormFlexRow',

  components: {
    FormItem: () => import('./FormItem.vue'), // to register the component correctly
  },

  inheritAttrs: false,

  inject: {
    filterSchema: 'filterSchema',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredChildrens() {
      return this.filterSchema(this.children);
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>

<style lang="scss">
.form-flex-row {
  display: flex;
  width: 100%;
  align-items: baseline;
  &__item {
    flex-grow: 1;
  }
}
</style>
