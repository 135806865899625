export const ENUM = {
  select: 'select',
  radio: 'radio',
  checkbox: 'checkbox',
  switch: 'switch',
};

export const LIST = {
  default: 'default',
  counter: 'counter',
};
