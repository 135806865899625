<template>
  <form-multi-select
    ref="multiselect"
    v-model="modelWrapper"
    :label="label"
    :error-messages="errors"
    :query-items="queryItems"
    :item-text="getTranslatedText"
    :all-message="$t('label.all_contractors')"
    :item-value="item => item"
    :value-comparator="$options.compareById"
    :is-filtered="isFiltered"
    placeholder-search="contractors_select_search_label"
    v-on="inheritedListeners"
  />
</template>

<script>
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchContractors } from '@/services/select';

// Components
import FormMultiSelect from './FormMultiselect.vue';

export default {
  name: 'SchemaFormFieldManyContractorsTypes',

  components: { FormMultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    isFiltered() {
      return Boolean(this.payload.clients);
    },
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload, config) {
      const { clients } = this.payload;

      return fetchContractors({ limit: 100, ...payload, ...this.payload, clients }, config).then(
        ({ results }) => results
      );
    },

    getTranslatedText(item) {
      return item.name;
    },
  },

  compareById,
};
</script>
