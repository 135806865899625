<template>
  <SearchBar
    :key="$route.name"
    v-model="modelWrapper"
    class="mb-6"
    :placeholder="label"
    single-line
    full-width
    hide-details
  />
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'FormFieldSearchBar',
  components: { SearchBar },
  props: {
    label: { type: String, default: '' },
    value: { type: String, required: true },
  },
  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
  },
};
</script>
