// eslint-disable-next-line import/prefer-default-export
export const rules = {
  REQUIRED: 'required',
  CONFIRMED: 'confirmed',
  MANY_REQUIRED: 'manyRequired',
  POSITIVE: 'positive',
  INTEGER: 'integer',
  URL: 'url',
  PERCENT: 'percent',
  ALL_LOADED: 'allLoaded',
  EMAIL: 'email',
  PHONE: 'phone',
};
