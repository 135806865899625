<template>
  <div class="schema-image-item rounded">
    <button type="button" @click="click">
      <v-img :src="localImageUrl" width="80" height="80" />
    </button>
    <div class="schema-image-item__hover-overlay"></div>
    <div v-if="loading || localLoading" class="schema-image-item__overlay">
      <v-progress-circular class="schema-image-item__loader" color="white" size="20" width="2" indeterminate />
    </div>

    <button v-else-if="selectable" type="button" class="schema-image-item__select" @click="click">
      <v-icon v-if="selected" size="24" color="primary">mdi-radiobox-marked</v-icon>
      <v-icon v-else size="24" color="white">mdi-radiobox-blank</v-icon>
    </button>

    <button v-else-if="editable" type="button" class="schema-image-item__close" @click="remove">
      <v-icon size="16" color="white">mdi-close</v-icon>
    </button>
  </div>
</template>

<script>
// Services
import mediaService from '@/services/media';
import client from '@/http/client';

// Utils
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';
import { checkHasProtocol } from '@/utils/validators';
import { flushPromises } from '@/utils/scheduler';

export default {
  name: 'FormSchemaImageItem',

  props: {
    imageUrl: { type: String, required: true },
    loading: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
  },

  data() {
    return {
      localImageUrl: undefined,
      localLoading: false,
    };
  },

  watch: {
    imageUrl: {
      handler(image) {
        if (image.search('data:image') !== -1) {
          this.localImageUrl = image;
          return;
        }

        const protocolFound = checkHasProtocol(image);

        if (protocolFound) {
          this.localImageUrl = image;
          return;
        }

        const fullImageUrl = getBaseUrlWithoutApi() + image;

        this.getPrivateMedia(fullImageUrl);
      },
      immediate: true,
    },
  },

  methods: {
    click() {
      this.$emit('click');
    },

    remove() {
      this.$emit('remove');
    },
    async getPrivateMedia(fullImageUrl) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      this.localLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestTasks = cancelSource.cancel;

        mediaService
          .getPrivateMedia(fullImageUrl)
          .then(imageUrl => {
            this.localImageUrl = imageUrl;
          })
          .finally(() => {
            this.localLoading = false;
          });
      } finally {
        this.$options.cancelRequest = null;
      }
    },
  },
  cancelRequest: null,
};
</script>

<style lang="scss" scoped>
.schema-image-item {
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: relative;
  border: none;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#1e1e22, 0.5);
  }

  &__hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#1e1e22, 0.5);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &:hover > &__hover-overlay {
    opacity: 1;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &__select {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
