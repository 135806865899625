<template>
  <v-dialog
    v-model="isOpen"
    class="photo-slider"
    max-width="800"
    content-class="elevation-0"
    :fullscreen="media.isMobile"
  >
    <v-card v-if="!media.isMobile">
      <v-card-title>
        <h1 class="text-h4 font-weight-bold">{{ title }}</h1>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="d-flex flex-row align-center">
          <icon-button v-if="imagesCount > 1" @click="previous">
            <v-icon>mdi-arrow-left</v-icon>
          </icon-button>
          <v-carousel
            v-model="modelWrapper"
            class="mx-4"
            max-width="632"
            max-height="400"
            :show-arrows="false"
            hide-delimiters
          >
            <v-carousel-item v-for="image in images" :key="image" :src="image" />
          </v-carousel>
          <icon-button v-if="imagesCount > 1" @click="next">
            <v-icon>mdi-arrow-right</v-icon>
          </icon-button>
        </div>
        <div v-if="imagesCount > 1" class="d-flex justify-center text-subtitle-1 primary--text mt-6">
          <span class="secondary py-1 px-5 rounded-pill"> {{ value + 1 }}/{{ imagesCount }} </span>
        </div>
      </v-card-text>
    </v-card>

    <v-card v-else width="100%" class="d-flex flex-column align-center rounded-0">
      <v-card-title class="secondary py-4 v-picker--full-width photo-slider__title">
        <v-spacer />
        <span class="primary--text"> {{ value + 1 }}/{{ imagesCount }} </span>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-0 ma-0 flex-grow-1 d-flex align-center black">
        <v-carousel v-model="modelWrapper" :show-arrows="false" hide-delimiters>
          <v-carousel-item v-for="image in images" :key="image" width="632" height="400" :src="image">
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// Utils
import { createModelWrapper } from '@/utils/components';

// Components
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'PhotoSlider',

  components: {
    IconButton,
  },

  inject: ['media'],

  props: {
    images: { type: Array, default: () => [] },
    value: { type: Number, default: 0 },
    title: { type: String, default: '' },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),

    imagesCount() {
      return this.images.length;
    },
  },

  methods: {
    open() {
      this.$emit('open');
      this.isOpen = true;
    },

    close() {
      this.$emit('close');
      this.isOpen = false;
    },

    previous() {
      if (this.value === 0) {
        this.modelWrapper = this.images.length - 1;
        return;
      }

      this.modelWrapper -= 1;
    },

    next() {
      this.modelWrapper += 1;
    },
  },
};
</script>

<style lang="scss">
.photo-slider {
  &__title {
    width: 100%;
  }
}
</style>
