import {
  sub,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  startOfMonth,
  endOfMonth,
  endOfYear,
  startOfYear,
  format,
} from 'date-fns';
import { isEqual } from '@/utils/common';

export function formatRange(dates, formatter = 'yyyy-MM-dd') {
  return dates.map(date => format(date, formatter));
}

export function getToday() {
  return formatRange([new Date(), new Date()]);
}

export function getYesterday() {
  return formatRange([startOfYesterday(), startOfYesterday()]);
}

export function getThisWeek(date = new Date()) {
  const startDate = startOfWeek(date);
  const endDate = endOfWeek(date);
  return formatRange([startDate, endDate]);
}

export function getPreviousWeek(date = new Date()) {
  const previousDayWeek = sub(startOfWeek(date), { days: 1 });
  const startDate = startOfWeek(previousDayWeek);
  const endDate = endOfWeek(previousDayWeek);
  return formatRange([startDate, endDate]);
}

export function getThisMonth(date = new Date()) {
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  return formatRange([startDate, endDate]);
}

export function getPreviousMonth(date = new Date()) {
  const previousDayMonth = sub(startOfMonth(date), { days: 1 });
  const startDate = startOfMonth(previousDayMonth);
  const endDate = endOfMonth(previousDayMonth);
  return formatRange([startDate, endDate]);
}

export function getThisYear(date = new Date()) {
  const startDate = startOfYear(date);
  const endDate = endOfYear(date);
  return formatRange([startDate, endDate]);
}

export function getPreviousYear(date = new Date()) {
  const previousDayYear = sub(startOfYear(date), { days: 1 });
  const startDate = startOfYear(previousDayYear);
  const endDate = endOfYear(previousDayYear);
  return formatRange([startDate, endDate]);
}

export const itemsRangeConfig = [
  {
    text: 'range.today',
    getValue: getToday,
    value: getToday(),
  },
  {
    text: 'range.yesterday',
    getValue: getYesterday,
    value: getYesterday(),
  },
  {
    text: 'range.this_week',
    getValue: getThisWeek,
    value: getThisWeek(),
  },
  {
    text: 'range.last_week',
    getValue: getPreviousWeek,
    value: getPreviousWeek(),
  },
  {
    text: 'range.this_month',
    getValue: getThisMonth,
    value: getThisMonth(),
  },
  {
    text: 'range.last_month',
    getValue: getPreviousMonth,
    value: getPreviousMonth(),
  },
  {
    text: 'range.this_year',
    getValue: getThisYear,
    value: getThisYear(),
  },
  {
    text: 'range.last_year',
    getValue: getPreviousYear,
    value: getPreviousYear(),
  },
];

export function getTextRange(range) {
  return itemsRangeConfig.find(item => isEqual(item.value, range))?.text || '';
}
