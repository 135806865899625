<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="modelWrapper"
    class="form-autocomplete"
    :label="label"
    :items="itemsWithValue"
    :loading="isLoading"
    :item-text="itemText"
    :item-value="itemValue"
    :search-input.sync="search"
    :value-comparator="compareValues"
    :error-messages="errors"
    return-object
    :disabled="disabled"
    v-on="inheritedListeners"
  >
    <template #append-item>
      <slot name="append-item"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import { debounce } from '@/utils/delay';
import { isFunction, isEqual } from '@/utils/common';

export default {
  name: 'SchemaFormAutocomplete',

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    queryItems: {
      type: Function,
      default: null,
    },
    compareValues: {
      type: Function,
      required: true,
    },
    itemText: {
      type: [String, Function],
      default: undefined,
    },
    itemValue: {
      type: [String, Function],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      search: null, // `null` don`t run watch effect
      items: [],
      debouncedFetchItems: null,
      firstChange: true,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    itemsWithValue() {
      if (!this.modelWrapper) return this.items;

      const foundItem = this.items.find(item => isEqual(item, this.modelWrapper));

      if (!foundItem) {
        return [this.modelWrapper, ...this.items];
      }

      return this.items;
    },
  },

  watch: {
    search(search) {
      if (search !== this.getItemText()) {
        this.debouncedFetchItems({ search });
      }

      if (this.modelWrapper?.firstName && this.search === '') {
        this.search = null;
        this.modelWrapper = null;
      }
    },

    modelWrapper() {
      if (this.firstChange) {
        // @todo изменить реализацию выяснив, причину возникновения ошибки
        this.$emit('validate');
        this.firstChange = false;
      }
    },
  },

  created() {
    this.debouncedFetchItems = debounce(this.fetchItems, 300);
    this.fetchItems();
  },

  methods: {
    fetchItems({ search = this.search } = {}) {
      this.isLoading = true;

      this.queryItems({ search: search || null })
        .then(items => {
          this.items = items;
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.autocomplete?.updateMenuDimensions();
        });
    },

    getItemText() {
      const { itemText, modelWrapper } = this;

      if (modelWrapper && typeof itemText === 'string') return modelWrapper[itemText];
      if (modelWrapper && isFunction(itemText)) return itemText(modelWrapper);

      return null;
    },
  },
};
</script>

<style lang="scss">
.form-autocomplete {
  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }

  .v-input__control > .v-input__slot:before {
    border-color: rgba($--blue-color-5, 0.15) !important;
  }
}
</style>
