<template>
  <div>
    <div v-if="label" class="text--secondary text-body-1 mb-2">{{ label }}</div>

    <div class="d-flex flex-wrap mr-n3">
      <image-item
        v-for="({ tempId, url, lazySrc, isLoading, selected }, index) in modelWrapper"
        :key="tempId"
        class="mr-3 mb-3"
        :image-url="url"
        :lazy-image-url="lazySrc"
        :loading="isLoading"
        :editable="editable"
        :selectable="selectable"
        :selected="selected"
        @remove="removeItem(index)"
        @click="onItemClick(index)"
      />

      <form-image-upload
        v-if="showUploadBtn"
        class="mb-3"
        :disabled="disableUpload"
        :multiple="!onlyOne"
        @input="addNewImage"
      />
      <photo-slider v-if="!selectable" ref="slider" v-model="currentSlide" :images="imagesForSlider" :title="title" />
    </div>
  </div>
</template>

<script>
// Utils
import { getUuid } from '@/utils/generators';
import { checkHasProtocol } from '@/utils/validators';
import { getBaseUrlWithoutApi } from '@/http/getBaseURL';

// Services
import mediaService from '@/services/media';
import announcementService from '@/services/announcement';

// Components
import PhotoSlider from '@/components/PhotoSlider.vue';
import ImageItem from './FormImageItem.vue';
import FormImageUpload from './FormImageUpload.vue';

export default {
  name: 'SchemaFormFieldImageList',

  components: { ImageItem, FormImageUpload, PhotoSlider },

  inheritAttrs: false,

  props: {
    value: { type: Array, default: () => [] },
    payload: { type: Object, default: () => ({}) },
    selectable: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    onlyOne: { type: Boolean, default: false },
    label: { type: String, default: '' },
    service: { type: String, default: 'media' },
  },

  data() {
    return {
      maxFileNumbers: 10,
      currentSlide: 0,
      imagesForSlider: [],
    };
  },

  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
        // Тригерит vee-validate
        this.$emit('blur');
      },
    },

    title() {
      return this.payload?.listTitle || this.label;
    },

    disableUpload() {
      return this.modelWrapper.length >= 10;
    },

    titleForSlider() {
      return this.payload?.title || '';
    },

    images() {
      return this.value.map(item => {
        return item.url;
      });
    },
    showUploadBtn() {
      return this.editable && this.onlyOne ? this.modelWrapper.length < 1 : this.editable;
    },
  },

  watch: {
    images: {
      immediate: true,
      async handler(images) {
        this.imagesForSlider = await Promise.all(
          images.map(image => {
            const protocolFound = checkHasProtocol(image);

            if (protocolFound) {
              return Promise.resolve(image);
            }

            const fullImageUrl = getBaseUrlWithoutApi() + image;
            return mediaService.getPrivateMedia(fullImageUrl);
          })
        );
      },
    },
  },

  methods: {
    onItemClick(index) {
      if (this.selectable) {
        this.selectItem(index);
      } else {
        this.openSlider(index);
      }
    },

    selectItem(index) {
      this.modelWrapper = this.modelWrapper.map((item, itemIndex) => {
        if (itemIndex === index) return { ...item, selected: true };
        return { ...item, selected: false };
      });
    },

    addNewImage(images) {
      const imagesArray = Array.from(images);

      imagesArray.slice(0, Math.max(0, this.maxFileNumbers - this.modelWrapper.length)).forEach(image => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(image);

        fileReader.addEventListener('load', async imageUrl => {
          const tempId = getUuid();
          const length = this.addItem({ url: imageUrl.target.result, tempId, isLoading: true });
          if (!length) return;

          let upload;

          if (this.service === 'media') {
            upload = mediaService.uploadImage;
          } else if (this.service === 'announcement') {
            upload = announcementService.uploadImage;
          } else {
            throw new Error('Unknown service');
          }

          upload(image)
            .then(tempImageData => {
              const index = this.findFileIndex(tempId);
              if (index === -1) {
                return;
              }
              const originImagesList = this.modelWrapper.slice().map(item => ({ ...item, selected: false }));

              const tempImageUrl = tempImageData.url;

              const newFields = {
                backgroundFileId: tempImageData.id,
                isLoading: false,
                url: tempImageUrl,
              };

              if (this.selectable) {
                newFields.selected = true;
              }

              originImagesList.splice(index, 1, { ...originImagesList[index], ...newFields });

              this.modelWrapper = originImagesList;
            })
            .catch(() => {
              const index = this.findFileIndex(tempId);
              if (index === -1) {
                return;
              }
              this.removeItem(index);
            });
        });
      });
    },

    removeItem(index) {
      const newValue = this.modelWrapper.slice();
      newValue.splice(index, 1);
      this.modelWrapper = newValue;
    },

    addItem(item) {
      const modelWrapper = [...this.modelWrapper, item];
      this.modelWrapper = modelWrapper;
      return modelWrapper.length;
    },

    openSlider(index) {
      this.currentSlide = index;
      this.$refs.slider.open();
    },
    findFileIndex(tempId) {
      return this.modelWrapper.findIndex(item => item.tempId === tempId);
    },
  },
};
</script>
