<template>
  <div class="form-row">
    <v-row class="my-0">
      <v-col v-for="item in filteredChildrens" :key="item.prop" class="py-0" cols="12" :md="true">
        <FormItem v-bind="item" :prop-path="getPropertyPath(item)">
          {{ item }}
        </FormItem>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SchemaFormRow',

  components: {
    FormItem: () => import('./FormItem.vue'), // to register the component correctly
  },

  inheritAttrs: false,

  inject: {
    filterSchema: 'filterSchema',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    filteredChildrens() {
      return this.filterSchema(this.children);
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>
