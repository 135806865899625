<template>
  <div class="d-flex flex-wrap">
    <v-checkbox
      v-for="item in sortedSelectOptions"
      :key="item.value"
      v-model="modelWrapper"
      :value="item.value"
      :label="item.text"
      class="mr-4 mt-1"
    />
  </div>
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import * as dictionaryTypes from '@/schemas/dictionaryTypes';

import { ENUM } from '@/constants/viewTypes';

import {
  fetchProjectTypes,
  fetchPropertyTypes,
  fetchParkingTypes,
  getInvoicePeriods,
  getClientsTypes,
  getServiceTypes,
  getRecurrencePeriods,
  getUnitiesStatus,
  getPaymentTypes,
  getRoomStatus,
  getApproval,
  getAreaUnit,
  getUnitsMeasurementItems,
  getAccountingItems,
  getServicesScenarioTypes,
  getTimes,
  getIntervalDays,
  getTypeOfRecipients,
} from '@/services/select';
import { compareByValue } from '@/utils/comparators';

const DICTIONARY_MAP = {
  [dictionaryTypes.PROJECT_TYPES]: fetchProjectTypes,
  [dictionaryTypes.PROPERTY_TYPES]: fetchPropertyTypes,
  [dictionaryTypes.PARKING_TYPES]: fetchParkingTypes,
  [dictionaryTypes.PERIOD_TYPES]: getInvoicePeriods,
  [dictionaryTypes.CLIENT_TYPES]: getClientsTypes,
  [dictionaryTypes.SERVICE_TYPES]: getServiceTypes,
  [dictionaryTypes.RECURRENCE_PERIODS]: getRecurrencePeriods,
  [dictionaryTypes.UNITY_STATUSES]: getUnitiesStatus,
  [dictionaryTypes.PAYMENT_TYPES]: getPaymentTypes,
  [dictionaryTypes.ROOM_STATUSES]: getRoomStatus,
  [dictionaryTypes.APPROVAL]: getApproval,
  [dictionaryTypes.AREA_UNIT]: getAreaUnit,
  [dictionaryTypes.UNITS_MEASUREMENT]: getUnitsMeasurementItems,
  [dictionaryTypes.ACCOUNTING]: getAccountingItems,
  [dictionaryTypes.SERVICES_SCENARIO_TYPES]: getServicesScenarioTypes,
  [dictionaryTypes.TIMES]: getTimes,
  [dictionaryTypes.INTERVAL_DAYS]: getIntervalDays,
  [dictionaryTypes.RECIPIENTS]: getTypeOfRecipients,
};

export default {
  name: 'SchemaFormFieldManyEnum',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    viewType: {
      type: String,
      default: ENUM.select,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    dictionary: {
      type: String,
      default: null,
    },
    queryParams: {
      type: [Object],
      default: () => ({}),
    },
    sort: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => ({ text: '', icon: '' }),
    },
  },

  data() {
    return {
      isLoading: false,
      items: [],
      firstChange: true,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    selectOptions() {
      if (!this.needsTranslated) {
        return this.items.map(({ name: text, value }) => ({ text, value }));
      }

      return this.items.map(({ name, value }) => ({ text: this.$t(name), value }));
    },

    sortedSelectOptions() {
      if (this.sort) {
        return this.selectOptions.slice().sort((a, b) => {
          if (a?.text > b?.text) return 1;
          if (a?.text < b?.text) return -1;
          return 0;
        });
      }

      return this.selectOptions;
    },

    isSelect() {
      return this.viewType === ENUM.select;
    },

    isRadio() {
      return this.viewType === ENUM.radio;
    },

    isCheckbox() {
      return this.viewType === ENUM.checkbox;
    },

    needsTranslated() {
      return ['interval_days', 'times'].every(item => {
        return item !== this.dictionary;
      });
    },

    isAreaUnit() {
      return this.dictionary === dictionaryTypes.AREA_UNIT;
    },
  },

  watch: {
    modelWrapper() {
      if (this.firstChange) {
        this.$emit('validate');
        this.firstChange = false;
      }
    },
  },

  created() {
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      const fetchItems = DICTIONARY_MAP[this.dictionary];

      if (!fetchItems) {
        // eslint-disable-next-line
        console.warn(`[SchemaFormFieldEnum]: unknown dictionary ${this.dictionary}`);
        return;
      }

      this.isLoading = true;
      fetchItems(this.queryParams)
        .then(items => {
          this.items = items;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  compareByValue,
};
</script>

<style lang="scss">
.form-enum-select {
  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }
}
</style>
