<script>
import { normalizeDate } from '@/utils/contracts';

export default {
  name: 'ContractPeriod',

  functional: true,

  props: {
    rentalPeriodEndDate: { type: String, default: '' },
    rentalPeriodStartDate: { type: String, default: '' },
    salesContractDate: { type: String, default: '' },
  },

  render(h, { props }) {
    if (!props.rentalPeriodEndDate && !props.rentalPeriodStartDate) {
      return [normalizeDate(props.salesContractDate)];
    }

    return [`${normalizeDate(props.rentalPeriodStartDate)} - ${normalizeDate(props.rentalPeriodEndDate)}`];
  },
};
</script>
