<template>
  <v-select
    v-if="isSelect"
    v-model="modelWrapper"
    class="form-enum-select"
    :loading="isLoading"
    :label="label"
    :error-messages="errors"
    :item-value="item => item"
    :items="sortedSelectOptions"
    :value-comparator="$options.compareByValue"
    v-on="inheritedListeners"
  >
    <template v-if="tooltip.text" #prepend-inner>
      <v-tooltip bottom @click.prevent>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="blue50" dark v-bind="attrs" v-on="on">
            {{ tooltip.icon }}
          </v-icon>
        </template>
        <span>{{ $t(tooltip.text) }}</span>
      </v-tooltip>
    </template>
  </v-select>
  <v-radio-group
    v-else-if="isRadio"
    v-model="modelWrapper"
    row
    :loading="isLoading"
    :error-messages="errors"
    v-on="inheritedListeners"
  >
    <v-radio v-for="item in sortedSelectOptions" :key="item.value" :value="item.value" :label="item.text">
      <template v-slot:label>
        {{ item.text }}
        <sup v-if="isAreaUnit">
          2
        </sup>
      </template>
    </v-radio>
  </v-radio-group>
  <div v-else-if="isCheckbox" class="d-flex">
    <v-checkbox
      v-for="item in sortedSelectOptions"
      :key="item.value"
      v-model="modelWrapper"
      :value="item.value"
      :label="item.text"
      class="mr-4"
    >
      <template v-slot:label>
        {{ item.text }}
        <sup v-if="isAreaUnit">
          2
        </sup>
      </template>
    </v-checkbox>
  </div>
  <v-switch v-else-if="isSwitch" v-model="modelWrapper" :label="label" v-bind="$attrs"></v-switch>
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import * as dictionaryTypes from '@/schemas/dictionaryTypes';

import { ENUM } from '@/constants/viewTypes';

import {
  fetchProjectTypes,
  fetchPropertyTypes,
  fetchParkingTypes,
  getInvoicePeriods,
  getClientsTypes,
  getServiceTypes,
  getRecurrencePeriods,
  getUnitiesStatus,
  getPaymentTypes,
  getRoomStatus,
  getApproval,
  getAreaUnit,
  getUnitsMeasurementItems,
  getAccountingItems,
  getServicesScenarioTypes,
  getTimes,
  getIntervalDays,
  getTypeOfRecipients,
  getFormFields,
} from '@/services/select';
import { compareByValue } from '@/utils/comparators';

const DICTIONARY_MAP = {
  [dictionaryTypes.PROJECT_TYPES]: fetchProjectTypes,
  [dictionaryTypes.PROPERTY_TYPES]: fetchPropertyTypes,
  [dictionaryTypes.PARKING_TYPES]: fetchParkingTypes,
  [dictionaryTypes.PERIOD_TYPES]: getInvoicePeriods,
  [dictionaryTypes.CLIENT_TYPES]: getClientsTypes,
  [dictionaryTypes.SERVICE_TYPES]: getServiceTypes,
  [dictionaryTypes.RECURRENCE_PERIODS]: getRecurrencePeriods,
  [dictionaryTypes.UNITY_STATUSES]: getUnitiesStatus,
  [dictionaryTypes.PAYMENT_TYPES]: getPaymentTypes,
  [dictionaryTypes.ROOM_STATUSES]: getRoomStatus,
  [dictionaryTypes.APPROVAL]: getApproval,
  [dictionaryTypes.AREA_UNIT]: getAreaUnit,
  [dictionaryTypes.UNITS_MEASUREMENT]: getUnitsMeasurementItems,
  [dictionaryTypes.ACCOUNTING]: getAccountingItems,
  [dictionaryTypes.SERVICES_SCENARIO_TYPES]: getServicesScenarioTypes,
  [dictionaryTypes.TIMES]: getTimes,
  [dictionaryTypes.INTERVAL_DAYS]: getIntervalDays,
  [dictionaryTypes.RECIPIENTS]: getTypeOfRecipients,
  [dictionaryTypes.REQUEST_FIELDS_LIST]: getFormFields,
  [dictionaryTypes.TABLE_COLUMNS]: getFormFields,
};

export default {
  name: 'SchemaFormFieldEnum',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    viewType: {
      type: String,
      default: ENUM.select,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    dictionary: {
      type: String,
      default: null,
    },
    queryParams: {
      type: [Object],
      default: () => ({}),
    },
    sort: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => ({ text: '', icon: '' }),
    },
  },

  data() {
    return {
      isLoading: false,
      items: [],
      firstChange: true,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    selectOptions() {
      if (!this.needsTranslated) {
        return this.items.map(({ name: text, value }) => ({ text, value }));
      }

      return this.items.map(({ name, value }) => ({ text: this.$t(name), value }));
    },

    sortedSelectOptions() {
      if (this.sort) {
        return this.selectOptions.slice().sort((a, b) => {
          if (a?.text > b?.text) return 1;
          if (a?.text < b?.text) return -1;
          return 0;
        });
      }

      return this.selectOptions;
    },

    isSelect() {
      return this.viewType === ENUM.select;
    },

    isRadio() {
      return this.viewType === ENUM.radio;
    },

    isCheckbox() {
      return this.viewType === ENUM.checkbox;
    },

    isSwitch() {
      return this.viewType === ENUM.switch;
    },

    needsTranslated() {
      return ['interval_days', 'times'].every(item => {
        return item !== this.dictionary;
      });
    },

    isAreaUnit() {
      return this.dictionary === dictionaryTypes.AREA_UNIT;
    },
  },

  watch: {
    modelWrapper() {
      if (this.firstChange) {
        this.$emit('validate');
        this.firstChange = false;
      }
    },
  },

  created() {
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      const fetchItems = DICTIONARY_MAP[this.dictionary];

      if (!fetchItems) {
        // eslint-disable-next-line
        console.warn(`[SchemaFormFieldEnum]: unknown dictionary ${this.dictionary}`);
        return;
      }

      this.isLoading = true;
      fetchItems(this.queryParams)
        .then(items => {
          this.items = items;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  compareByValue,
};
</script>

<style lang="scss">
.form-enum-select {
  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }
}
</style>
