<template>
  <div class="calendar-with-panel">
    <div v-if="media.isMobile">
      <v-chip-group v-model="selectItem" center-active color="blue20">
        <v-chip
          v-for="item in itemsRange"
          :key="item.text"
          outlined
          color="blue70"
          active-class="calendar-with-panel__chip--active"
          @click="selectDatePeriod(item.getValue)"
        >
          {{ $t(item.text) }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-list v-else class="calendar-with-panel__ranges" min-width="144">
      <v-list-item-group v-model="selectItem" color="blue20">
        <v-list-item v-for="item in itemsRange" :key="item.text" dense @click="selectDatePeriod(item.getValue)">
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-show="checkActive(item)" v-text="'mdi-check'"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-date-picker v-model="dateModel" :locale="$i18n.locale" range no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="cancelMenu">
        {{ $t('calendar.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="saveDate">
        OK
      </v-btn>
    </v-date-picker>
  </div>
</template>

<script>
import { isEqual } from '@/utils/common';
import { itemsRangeConfig } from '@/utils/dateRange';

export default {
  name: 'CalendarWithPAnel',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectItem: null,
      dates: [],
    };
  },
  inject: ['media'],
  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },
    itemsRange() {
      return itemsRangeConfig.map(item => {
        if (isEqual(item.getValue(), this.dateModel)) {
          return { ...item, active: true };
        }
        return { ...item, active: false };
      });
    },
    sortDates() {
      return this.dates?.slice().sort((a, b) => new Date(a) - new Date(b));
    },
    dateModel: {
      get() {
        return this.sortDates;
      },

      set(value) {
        this.dates = value;
        this.modelWrapper = this.sortDates;
      },
    },
    activeRangeIndex() {
      return this.itemsRange.findIndex(item => item.active);
    },
  },
  watch: {
    itemsRange(newVal) {
      const indexActive = newVal.findIndex(item => item.active);

      if (indexActive > -1) {
        this.selectItem = indexActive;
      } else {
        this.selectItem = null;
      }
    },
  },
  mounted() {
    this.dateModel = this.value;
  },
  methods: {
    // $nextTick для того чтобы была анимация при выборе значений через панель
    async selectDatePeriod(period) {
      this.dateModel = [];
      await this.$nextTick();
      this.dateModel = period();
    },
    cancelMenu() {
      this.dates = [];
      this.modelWrapper = this.dates;
      this.$emit('cancel-menu');
    },
    saveDate() {
      this.$emit('save-date');
    },
    checkActive(item) {
      return isEqual(item.getValue(), this.dateModel);
    },
  },
};
</script>

<style lang="scss">
.calendar-with-panel {
  display: flex;
  background: white;
  &__ranges {
    background: rgba($--blue-color-60, 0.5) !important;
  }

  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }
  @media (max-width: map.get($--screens, 'sm')) {
    flex-direction: column;
  }
  .v-chip-group .v-chip--active {
    border-color: $--blue-color-60 !important;
    background: $--blue-color-60 !important;
    color: inherit !important;
    .v-chip__content {
      color: $--blue-color-20;
    }
  }
}
</style>
