<template>
  <text-field
    v-model.number="modelWrapper"
    type="number"
    class="schema-form-field-number"
    :label="label"
    :error-messages="errors"
    v-on="inheritedListeners"
  >
    <template v-if="payload.currencySymbol" #append>
      {{ payload.currencySymbol }}
    </template>
  </text-field>
</template>

<script>
// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Components
import TextField from '@/components/TextField.vue';

export default {
  name: 'SchemaFormFieldAmount',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    payload: {
      type: Object,
      required: true,
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>
<style lang="scss">
.schema-form-field-number {
  .v-input__append-inner {
    justify-content: center;
    align-items: center;
    height: 28px;
  }
}
</style>
