<template>
  <text-field
    v-model="fieldModel"
    class="search-bar"
    prepend-inner-icon="mdi-magnify"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
// Components
import TextField from '@/components/TextField.vue';

export default {
  name: 'SearchBar',

  components: { TextField },

  props: {
    value: { type: String, required: true },
  },

  computed: {
    fieldModel: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value || '');
      },
    },
  },

  watch: {
    fieldModel(query) {
      this.updateQuery({ q: query || '' });
    },
  },

  mounted() {
    this.$emit('input', this.$route.query.q || '');
  },

  methods: {
    updateQuery(query) {
      const currentQuery = this.$route.query;

      this.$router.replace({ query: { ...currentQuery, ...query } });
    },
  },
};
</script>

<style lang="scss">
.search-bar {
  input[placeholder] {
    text-overflow: ellipsis !important;
  }
}
</style>
