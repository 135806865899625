<template>
  <v-row class="role-list">
    <v-col
      v-for="(item, index) in value"
      :key="item[uniqueKey] || item[$options.UNIQUE_KEY] || index"
      class="role-item"
      cols="12"
      sm="6"
    >
      <v-card elevation="0" color="#FAFAFA">
        <v-card-title class="align-start justify-space-between flex-nowrap">
          <h2 v-if="item.role">{{ item.role.name }}</h2>
          <v-btn
            class="btn-remove-role"
            fab
            dark
            x-small
            elevation="0"
            color="rgba(0, 0, 0, 0.54)"
            @click="deleteItem(index)"
          >
            <v-icon dark>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-chip-group v-if="item.employees" class="chips-employees" active-class="" column multiple>
            <v-chip v-if="item.employees.all">
              {{ $t('label.all_performers') }}
            </v-chip>
            <template v-else>
              <v-chip
                v-for="tag in filtredEmployees(item.employees.include)"
                :key="tag.id"
                :close="tag.id !== 'moreElements' && item.employees.include.length > 1"
                @click:close="removePerformer(tag, index)"
              >
                <template v-if="tag.id !== 'moreElements'">{{ tag.firstName }} {{ tag.lastName }}</template>
                <template v-else>
                  {{ tag.name }}
                </template>
              </v-chip>
            </template>
            <span />
            <form-modal
              title="Assign responsibility"
              :schema="$options.roleEditSchema"
              :initial-data="getInitialData(index)"
              @submit="saveRole($event, index)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="btn-edit-employees"
                  fab
                  dark
                  x-small
                  elevation="0"
                  color="#e6ebff"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark color="primary">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>

              <template #footer="{ valid }">
                <v-card-actions>
                  <v-btn large type="submit" color="primary" :disabled="!valid">ADD</v-btn>
                </v-card-actions>
              </template>
            </form-modal>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6">
      <form-modal
        title="Assign responsibility"
        :schema="$options.roleAddSchema"
        :initial-data="initialData"
        @submit="addRole($event, value.length)"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="secondary"
            class="role-item primary--text add-btn font-weight-bold"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t(addButtonText) }}
            <v-icon class="ms-1">mdi-plus</v-icon>
          </v-btn>
        </template>

        <template #footer="{ valid }">
          <v-card-actions>
            <v-btn large type="submit" color="primary" :disabled="!valid">ADD</v-btn>
          </v-card-actions>
        </template>
      </form-modal>
    </v-col>
  </v-row>
</template>

<script>
// Models
import { UNIQUE_KEY } from '@/schemas/config';
import { createModelData } from '@/schemas/createModelData';

// Schema
import { roleAddSchema, roleEditSchema } from '@/schemas/project.schema';

// Components
import FormModal from './FormModal.vue';

export default {
  UNIQUE_KEY,

  name: 'SchemaFormFieldTeamList',

  inject: {
    addPropertyValueItem: 'addPropertyValueItem',
    setPropertyValue: 'setPropertyValue',
    deletePropertyValueItem: 'deletePropertyValueItem',
    filterSchema: 'filterSchema',
  },

  components: {
    FormModal,
  },

  inheritAttrs: false,

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    uniqueKey: {
      type: String,
      default: null,
    },
    addButtonText: {
      type: String,
      default: 'button.assign_responsibility',
    },
  },

  data() {
    return {
      dialog: false,
      isValid: false,
      initialData: createModelData(roleAddSchema),
      viewPerformersLimit: 2,
    };
  },

  computed: {
    filteredChildrens() {
      return this.filterSchema(this.children);
    },
  },

  watch: {},

  methods: {
    addItem() {
      this.addPropertyValueItem({
        propPath: this.propPath,
        schema: this.children,
      });
    },

    addRole(role, index) {
      this.addItem();
      Object.keys(role).forEach(key => {
        this.setPropertyValue({ propPath: [...this.propPath, index, key], value: role[key] });
      });
    },

    getInitialData(index) {
      const initData = { ...this.value[index] };
      return initData;
    },

    saveRole(role, index) {
      Object.keys(role).forEach(key => {
        this.setPropertyValue({ propPath: [...this.propPath, index, key], value: role[key] });
      });
      this.dialog = false;
    },

    deleteItem(index) {
      this.deletePropertyValueItem({
        propPath: this.propPath,
        index,
      });
    },

    filtredEmployees(employees) {
      if (!employees) return null;
      if (employees.length > this.viewPerformersLimit) {
        const moreElemtns = employees.length - this.viewPerformersLimit;
        const showElements = employees.slice(0, this.viewPerformersLimit);
        return [
          ...showElements,
          {
            id: 'moreElements',
            name: `+${moreElemtns}`,
          },
        ];
      }
      return employees;
    },

    getPropertyPath(child, index) {
      return child.prop ? [...this.propPath, index, child.prop] : [...this.propPath, index];
    },

    removePerformer(performer, index) {
      const newPerformersValue = { ...this.value[index].employees };
      newPerformersValue.include = newPerformersValue.include.filter(item => item.id !== performer.id);
      this.setPropertyValue({ propPath: [...this.propPath, index, 'employees'], value: newPerformersValue });
    },
  },

  roleAddSchema,
  roleEditSchema,
};
</script>

<style lang="scss">
.add-btn {
  width: 100%;
  height: 53px !important;
}

.role-list {
  .role-item {
    min-height: 128px;

    .v-card {
      height: 100%;
    }
  }

  .v-chip {
    background: #e6ebff !important;
  }

  .btn-remove-role {
    width: 15px !important;
    height: 15px !important;

    .v-icon {
      width: 15px !important;
      height: 15px !important;
      font-size: 13px !important;
    }
  }

  .btn-edit-projects {
    width: 32px;
    height: 32px;
  }

  .v-slide-group__content {
    align-items: center;
  }
}
</style>
