<template>
  <div>
    <div class="entity-modify-header d-flex flex-wrap justify-space-between">
      <h1 class="text-md-h4 text-h5 mb-md-4 font-weight-bold px-2 py-2">
        {{ title }}
      </h1>

      <div class="ms-auto py-1 px-2">
        <slot></slot>
      </div>
    </div>

    <span class="error--text text--caption">{{ $t('entity.required') }}</span>
  </div>
</template>

<script>
export default {
  name: 'EntityModifyHeader',

  props: {
    title: { type: String, required: true },
  },
};
</script>

<style>
.entity-modify-header {
  margin: -8px;
}
</style>
