<template>
  <div class="time-picker">
    <text-field
      readonly
      :label="label"
      prepend-inner-icon="mdi-clock-time-nine"
      :value="value"
      @focus="onFocus"
      @blur="onBlur"
    />
    <vue-timepicker
      ref="timepicker"
      v-model="localValueModel"
      format="HH:mm"
      :hour-label="$t('label.hour')"
      :minute-label="$t('label.minute')"
      :hour-range="hourRange"
      :minute-range="minuteRange"
      :minute-interval="minuteInterval"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
// node_modules
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { setMinutes, format } from 'date-fns';

// Services
import timeService from '@/services/time';

// Utils
import { roundMinutes } from '@/utils/dateFormatting';

// Components
import TextField from '@/components/TextField.vue';

export default {
  name: 'TimePicker',

  components: { VueTimepicker, TextField },

  inheritAttrs: false,

  props: {
    value: { type: String, default: '' },
    isToday: { type: Boolean, default: false },
    label: { type: String, default: '' },
    minuteInterval: { type: Number, default: 1 },
  },

  data() {
    return {
      localValue: '',
      currentDate: new Date(),
    };
  },

  computed: {
    localValueModel: {
      get() {
        return this.localValue;
      },

      set(value) {
        this.localValue = value;
        this.$emit('input', value);
      },
    },

    hourRange() {
      if (!this.isToday) return undefined;

      const currentHours =
        this.currentDate.getMinutes() > 55 ? (this.currentDate.getHours() + 1) % 24 : this.currentDate.getHours();

      return [[currentHours, 23]];
    },

    minuteRange() {
      const localHours = this.localValue?.split(':')[0];

      if (!this.isToday || Number(localHours) > this.currentDate.getHours()) {
        return undefined;
      }

      return [[this.currentDate.getMinutes(), 60]];
    },
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue === oldValue || newValue === null) return;

        this.localValue = newValue;

        const newValueHour = Number(newValue.split(':')[0]);

        if (this.isToday && newValueHour === this.hourRange[0][0]) {
          this.normalizeMinuteInRange();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    timeService.subscribe(this.setDate);
  },

  beforeDestroy() {
    timeService.unsubscribe(this.setDate);
  },

  methods: {
    onFocus() {
      this.$refs.timepicker.onFocus();
    },

    onBlur() {
      this.$refs.timepicker.onBlur();
    },

    setDate(date) {
      this.currentDate = date;
    },

    normalizeMinuteInRange() {
      const currentMinutes = Number(this.localValue.split(':')[1]);

      if (this.minuteRange?.[0]?.[0] > currentMinutes) {
        const roundedMinutes = roundMinutes(this.currentDate.getMinutes(), this.minuteInterval);

        const result = setMinutes(this.currentDate, roundedMinutes);

        this.localValueModel = format(result, 'HH:mm');
      }
    },
  },
};
</script>

<style lang="scss">
.time-picker {
  position: relative;
  display: flex;

  .vue__time-picker {
    width: 0;
    position: static;

    .controls {
      display: none;
    }

    input {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
    }

    .dropdown.drop-down {
      width: 100%;
      transform: translateY(23px);

      .select-list {
        width: 100%;

        .active {
          background-color: $--blue-color-60;
          color: $--black-color-0;
        }
      }
    }
  }
}
</style>
