<template>
  <div class="schema-form-field-script-list d-flex flex-column">
    <validation-observer v-slot="{ valid }">
      <FormTitle v-if="hasTitle" :label="label" :class="{ 'mb-0': isEmpty }" :remove-margins="isEmpty" />

      <div
        v-for="(item, index) in value"
        :key="item[uniqueKey] || item[$options.UNIQUE_KEY] || index"
        class="d-flex align-start "
        :class="[index === 0 ? 'mt-2' : 'mt-10']"
      >
        <div class="d-flex flex-column flex-grow-1">
          <div v-if="hasCounter" class="d-flex align-center justify-space-between">
            <FormTitle v-if="required" class="my-0" remove-margins :label="`${label} ${index + 1} *`" />
            <FormTitle v-else class="my-0" remove-margins :label="`${label} ${index + 1}`" />

            <v-btn
              color="secondary"
              class="schema-form-field-script-list__button primary--text elevation-0"
              :class="{ 'schema-form-field-script-list__button--hide': !isMinFilled }"
              :disabled="!isMinFilled"
              @click="deleteItem(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <FormFieldScriptItem :schema="filteredChildrens" :index="index" :prop-path="propPath" />
        </div>

        <div v-if="!hasCounter" class="ml-2">
          <v-btn
            color="secondary"
            class="schema-form-field-script-list__button primary--text elevation-0"
            :class="{ 'schema-form-field-script-list__button--hide': !isMinFilled }"
            :disabled="!isMinFilled"
            @click="deleteItem(index)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="d-flex">
        <v-btn
          class="primary--text elevation-0"
          :class="[isEmpty ? 'mt-0' : 'mt-5']"
          :disabled="isFilled || !valid"
          text
          @click="!isFilled && addItem()"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t(addButtonText) }}
        </v-btn>
      </div>
    </validation-observer>
  </div>
</template>

<script>
// Constants
import { ValidationObserver } from 'vee-validate';
import { LIST } from '@/constants/viewTypes';

// Models
import { UNIQUE_KEY } from '@/schemas/config';

// Components
import FormTitle from './FormTitle.vue';
import FormFieldScriptItem from './FormFieldScriptItem.vue';

export default {
  UNIQUE_KEY,

  name: 'SchemaFormFieldScriptList',

  inject: {
    addPropertyValueItem: 'addPropertyValueItem',
    deletePropertyValueItem: 'deletePropertyValueItem',
    filterSchema: 'filterSchema',
  },

  components: {
    FormTitle,
    ValidationObserver,
    FormFieldScriptItem,
  },

  inheritAttrs: false,

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    viewType: {
      type: String,
      default: LIST.default,
    },
    addButtonText: {
      type: String,
      default: 'button.add_inventory',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scriptType: '',
    };
  },

  computed: {
    filteredChildrens() {
      return this.filterSchema(this.children);
    },
    isFilled() {
      if (this.max === undefined || this.max === null || !this.value) {
        return false;
      }
      return this.value.length >= this.max;
    },

    isMinFilled() {
      if (this.min === undefined || this.min === null) {
        return true;
      }
      return this.value?.length > this.min;
    },

    hasCounter() {
      return this.viewType === LIST.counter;
    },

    isEmpty() {
      return this.value.length === 0;
    },

    hasTitle() {
      return !this.hasCounter && this.label;
    },
  },

  methods: {
    addItem() {
      this.addPropertyValueItem({
        propPath: this.propPath,
        schema: this.children,
      });
    },
    deleteItem(index) {
      this.deletePropertyValueItem({
        propPath: this.propPath,
        index,
      });
    },

    getPropertyPath(child, index) {
      return child.prop ? [...this.propPath, index, child.prop] : [...this.propPath, index];
    },
  },
};
</script>

<style lang="scss">
.schema-form-field-script-list {
  &__container {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__button {
    min-width: 36px !important;
    width: 36px;
    height: 36px;
    &--hide {
      opacity: 0;
    }
  }
}
</style>
