<template>
  <v-checkbox v-model="modelWrapper" type="number" :label="label" :error-messages="errors" v-on="inheritedListeners" />
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '../../utils/components';

export default {
  name: 'SchemaFormFieldBoolean',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>
