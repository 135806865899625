import { isPlainObject } from './common';

/**
 * Clear data from keys with mutations
 * @param {Object} data - target object
 * @param {String[]} [keys=] - keys list that will be deleted from target object
 * @param {Boolean} [recursively=] - delete key properties from target object recursively
 */
// eslint-disable-next-line import/prefer-default-export
export function excludeKeys(data, keys = [], recursively = false) {
  Object.entries(data).forEach(([key, value]) => {
    if (keys.includes(key)) {
      // eslint-disable-next-line no-param-reassign
      delete data[key];
    } else if (recursively) {
      const isObject = isPlainObject(value);
      const isArray = Array.isArray(value);

      if (isObject) {
        excludeKeys(value, keys, recursively);
      } else if (isArray) {
        value.forEach(itemValue => excludeKeys(itemValue, keys, recursively));
      }
    }
  });

  return data;
}
