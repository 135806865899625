<template>
  <div class="datepicker-with-panel">
    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y left min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field :value="translatedModel" readonly :label="label" v-bind="attrs" v-on="on"></v-text-field>
      </template>
      <calendar-with-panel v-model="dateModel" @cancel-menu="cancelMenu" @save-date="saveDate" />
    </v-menu>
  </div>
</template>

<script>
import format from 'date-fns/format';
import { translateDate } from '@/utils/dateFormatting';

import { getTextRange } from '@/utils/dateRange';
import { isEqual } from '@/utils/common';
import CalendarWithPanel from './CalendarWithPanel.vue';

export default {
  name: 'DatePickerWithPanel',
  components: { CalendarWithPanel },
  props: {
    value: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    menu: false,
    selectItem: -1,
    dates: [],
  }),
  computed: {
    modelWrapper: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit('input', value);
      },
    },

    dateModel: {
      get() {
        return this.dates;
      },

      set(value) {
        this.dates = value;
        this.modelWrapper = value;
      },
    },

    translatedModel() {
      if (this.rangeText) {
        return this.$t(this.rangeText);
      }
      return this.dates
        .map(date => {
          return translateDate(this.formatDate(date));
        })
        .join(' - ');
    },
    rangeText() {
      return getTextRange(this.dates);
    },
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.dateModel = newValue;
        }
      },

      immediate: true,
    },
  },

  methods: {
    cancelMenu() {
      this.menu = false;
      this.modelWrapper = this.dates;
    },
    saveDate() {
      this.menu = false;
    },
    formatDate(date) {
      const noFormatDate = new Date(date);

      return format(noFormatDate, 'd L yyyy');
    },
  },
};
</script>

<style lang="scss">
.datepicker-with-panel {
  &__ranges {
    background: rgba($--blue-color-60, 0.5) !important;
  }
  &__menu {
    display: flex;
    background: white;
  }
  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }
}
</style>
