<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="modelWrapper"
    class="form-autocomplete"
    :label="label"
    :items="statuses"
    :error-messages="errors"
    :clearable="clearable"
    return-object
    v-on="inheritedListeners"
  />
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

export default {
  name: 'SchemaFormClientType',

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      statuses: [
        {
          id: 1,
          value: 'owner',
          text: this.$t('client.owner'),
        },
        {
          id: 2,
          value: 'tenant',
          text: this.$t('client.tenant'),
        },
        {
          id: 3,
          value: 'short_term_tenant',
          text: this.$t('client.short_term_tenant'),
        },
      ],
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>
