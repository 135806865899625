<template>
  <span>
    <FormSubtitle :remove-margins="removeMargins" v-bind="$attrs">
      <slot>
        {{ label }}
      </slot>
    </FormSubtitle>

    <form-item v-for="item in filteredChildrens" :key="item.prop" v-bind="item" :prop-path="getPropertyPath(item)" />
  </span>
</template>

<script>
import FormSubtitle from './FormSubtitle.vue';

export default {
  name: 'SchemaFormSubtitle',

  components: {
    FormItem: () => import('./FormItem.vue'), // to register the component correctly
    FormSubtitle,
  },

  inheritAttrs: false,

  inject: {
    filterSchema: 'filterSchema',
  },

  props: {
    propPath: {
      type: Array,
      required: true,
    },

    children: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      required: true,
    },

    removeMargins: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    filteredChildrens() {
      return this.filterSchema(this.children);
    },
  },

  methods: {
    getPropertyPath(item) {
      return item.prop ? [...this.propPath, item.prop] : this.propPath;
    },
  },
};
</script>
