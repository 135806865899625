<template>
  <FormAutocomplete
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    item-text="name"
    :item-value="item => item"
    :compare-values="$options.compareById"
    v-on="inheritedListeners"
  />
</template>

<script>
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

import { fetchCategories } from '@/services/select';
import FormAutocomplete from './FormAutocomplete.vue';

export default {
  compareById,

  name: 'SchemaFormFieldCategory',

  components: {
    FormAutocomplete,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  methods: {
    queryItems(payload) {
      return fetchCategories({ ...payload, ...this.payload }).then(({ results }) => {
        return results.map(item => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
  },
};
</script>
