<template>
  <text-field
    v-model.number="modelWrapper"
    type="number"
    :label="label"
    :error-messages="errors"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Components
import TextField from '@/components/TextField.vue';

export default {
  name: 'SchemaFormFieldPercent',

  components: { TextField },

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>
