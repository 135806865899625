<template>
  <v-btn
    :width="width"
    :height="width"
    :min-width="width"
    class="elevation-0 icon-button"
    :class="classes"
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',

  props: {
    color: { type: String, default: 'secondary' },
    width: { type: Number, default: 44 },
  },

  computed: {
    classes() {
      if (this.color === 'secondary') {
        return 'primary--text';
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
// .icon-button {
//   &:not(.not-before):not(:hover):not(:focus)::before {
//     opacity: 0.08 !important;
//   }
// }
</style>
