<template>
  <div>
    <span>
      <FormSubtitle>
        <slot>
          {{ $t('requests.pending') }}
        </slot>
      </FormSubtitle>

      <ValidationProvider ref="rolesPending" v-slot="{ errors }" rules="manyRequired">
        <multi-select
          v-model="value.rolesPending"
          :loading="isLoading"
          :items="items"
          :all-message="$t('label.all_roles')"
          item-text="name"
          :item-value="item => item"
          :can-select-all="canSelectAll"
          :search="search"
          :label="label"
          :is-filtered="isFiltered"
          :error-messages="errors"
          v-bind="$attrs"
          @update:search="setSearch"
          v-on="inheritedListeners"
        />
      </ValidationProvider>
    </span>

    <span>
      <FormSubtitle>
        <slot>
          {{ $t('requests.in_progress') }}
        </slot>
      </FormSubtitle>

      <ValidationProvider ref="rolesInProgress" v-slot="{ errors }" rules="manyRequired">
        <multi-select
          v-model="value.rolesInProgress"
          :loading="isLoading"
          :items="items"
          :all-message="$t('label.all_roles')"
          item-text="name"
          :item-value="item => item"
          :can-select-all="canSelectAll"
          :search="search"
          :label="label"
          :is-filtered="isFiltered"
          :error-messages="errors"
          v-bind="$attrs"
          @update:search="setSearch"
          v-on="inheritedListeners"
        />
      </ValidationProvider>
    </span>

    <span>
      <FormSubtitle>
        <slot>
          {{ $t('requests.ready') }}
        </slot>
      </FormSubtitle>

      <ValidationProvider ref="rolesCompleted" v-slot="{ errors }" rules="manyRequired">
        <multi-select
          v-model="value.rolesCompleted"
          :loading="isLoading"
          :items="items"
          :all-message="$t('label.all_roles')"
          item-text="name"
          :item-value="item => item"
          :can-select-all="canSelectAll"
          :search="search"
          :label="label"
          :is-filtered="isFiltered"
          :error-messages="errors"
          v-bind="$attrs"
          @update:search="setSearch"
          v-on="inheritedListeners"
        />
      </ValidationProvider>
    </span>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import client from '@/http/client';
import { debounce } from '@/utils/delay';
import { flushPromises } from '@/utils/scheduler';

// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchManyRoles } from '@/services/select';

// Components
import MultiSelect from '@/components/MultiSelect.vue';
import FormSubtitle from './FormSubtitle.vue';

export default {
  name: 'SchemaFormFieldManyRolesGroup',

  components: { ValidationProvider, FormSubtitle, MultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      canSelectAll: false,
      items: [],
      search: null,
      isLoading: false,
      debouncedFetchItems: null,
      foundItems: [],
    };
  },

  computed: {
    rolesPending() {
      return this.value.rolesPending;
    },
    rolesInProgress() {
      return this.value.rolesInProgress;
    },
    rolesCompleted() {
      return this.value.rolesCompleted;
    },
    modelWrapper: createModelWrapper('value', 'input'),
    modelWrapperRolesPending: createModelWrapper('rolesPending', 'input'),
    modelWrapperRolesInProgress: createModelWrapper('rolesInProgress', 'input'),
    modelWrapperRolesCompleted: createModelWrapper('rolesCompleted', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    isFiltered() {
      return Boolean(this.payload.projects);
    },
  },

  watch: {
    rolesPending(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        this.modelWrapper.rolesPending = value;
        this.$refs.rolesPending.validate();
      }
    },
    rolesInProgress(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        this.modelWrapper.rolesInProgress = value;
        this.$refs.rolesInProgress.validate();
      }
    },
    rolesCompleted(value, oldValue) {
      if (!isEqual(value, oldValue)) {
        this.modelWrapper.rolesCompleted = value;
        this.$refs.rolesCompleted.validate();
      }
    },
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.debouncedFetchItems();
      }
    },
  },

  created() {
    this.debouncedFetchItems = debounce(this.fetchItems);
    this.fetchItems();
  },

  methods: {
    async fetchItems({ search = this.search } = {}) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;

      this.isLoading = true;
      this.items = [];

      this.queryItems({ search }, { cancelToken: cancelSource.token })
        .then(items => {
          this.items = items;
        })
        .finally(() => {
          this.$options.cancelRequest = null;
          this.isLoading = false;
        });
    },

    setSearch(search) {
      this.search = search;
      this.debouncedFetchItems();
    },
    queryItems(payload, config) {
      this.canSelectAll = false;
      return fetchManyRoles(this.payload, { limit: 100, ...payload }, config).then(({ results }) => {
        // this.canSelectAll = !!results.length;
        return results;
      });
    },
  },

  compareById,
};
</script>
