import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.STRING,
    label: 'role.title',
    prop: 'name',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.CREDENTIALS_VARIANT,
    label: 'role.credentials',
    prop: 'accessLevels',
    payload: [
      {
        param: 'isSelf',
        from: ['isSelf'],
      },
    ],
  },
];

export const roleAddSchema = [
  {
    type: schemaItemTypes.ROLE,
    label: 'employee.role',
    prop: 'role',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: 'projects',
    rules: [rules.MANY_REQUIRED],
  },
];

export const roleEditSchema = [
  {
    type: schemaItemTypes.ROLE,
    label: 'employee.role',
    prop: 'role',
    disabled: true,
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: 'projects',
    rules: [rules.MANY_REQUIRED],
  },
];
