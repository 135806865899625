<template>
  <v-menu
    class="form-unit-contracts"
    content-class="form-unit-contracts__menu"
    :min-width="minWidth"
    :close-on-content-click="false"
    offset-y
    left
  >
    <template #activator="{ on, attrs }">
      <v-btn color="secondary" class="primary--text" elevation="0" v-bind="attrs" v-on="on">{{
        $t('unit.contracts_show_planned')
      }}</v-btn>
    </template>

    <v-row v-if="!usages.length">
      <v-col :cols="12" :md="6">
        {{ $t('unit.contract_no_planned') }}
      </v-col>
    </v-row>
    <template v-else>
      <v-row v-for="usage in usages" :key="usage.usageId" no-gutters class="mb-6">
        <v-col :cols="12" :md="6">
          <view-field :label="$t('unit.contract_rent_period')">
            <contract-period
              :rental-period-end-date="usage.rentalPeriodEndDate"
              :rental-period-start-date="usage.rentalPeriodStartDate"
              :sales-contract-date="usage.salesContractDate"
            />
          </view-field>
        </v-col>

        <v-col :cols="12" :md="6">
          <view-field-client :label="$t('unit.contract_client')" :value="usage.client" />
        </v-col>
      </v-row>
    </template>

    <div v-if="isFirstLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>

    <v-btn
      v-else-if="displayMoreButton"
      :loading="isLoading"
      secondary
      class="primary--text"
      elevation="0"
      color="secondary"
      :block="isBlockButton"
      @click="fetchUsages"
      >{{ $t('unit.contract_show_more') }}</v-btn
    >
  </v-menu>
</template>

<script>
import ViewField from '@/components/ViewField.vue';
import ViewFieldClient from '@/components/schema/ViewFieldClient.vue';
import usersService, { UsersService } from '@/services/users';
import ContractPeriod from '@/components/ContractPeriod.vue';

const USAGES_LIMIT = 5;

export default {
  name: 'FormUnitContracts',

  components: { ViewField, ViewFieldClient, ContractPeriod },

  // props: {
  //   value: { type: Number, required: true },
  // },

  data() {
    return {
      usages: [],
      offset: 0,
      count: 0,
      isLoading: false,
    };
  },

  computed: {
    // id() {
    //   return this.value;
    // },

    isFirstLoading() {
      return this.isLoading && this.usages.length === 0;
    },

    displayMoreButton() {
      return this.count > this.offset;
    },

    isBlockButton() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    minWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'calc(100% - 24px)';
      }

      return 548;
    },
  },

  created() {
    this.fetchUsages();
  },

  methods: {
    fetchUsages() {
      this.isLoading = true;

      return usersService
        .getUsages({
          unit: this.id,
          status: UsersService.usagesStatus.BOOKED,
          limit: USAGES_LIMIT,
          offset: this.offset,
        })
        .then(usages => {
          this.usages = [...this.usages, ...usages.results];
          this.offset += usages.results.length;
          this.count = usages.count;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.form-unit-contracts {
  &__menu {
    padding: 12px;
    background-color: $--white-color-0;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      min-width: 548px;
      max-height: 492px;
      padding: 24px;
    }
  }
}
</style>
