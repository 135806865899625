/* eslint-disable */
import client from '@/http/client';

const BASE_URL = '/requests';

class RequestsService {

  async getRequests(params, data) {
    return client.post(`${BASE_URL}/select/request/`, data, { params });
  }

  async getRequestById(id) {
    return client.get(`${BASE_URL}/${id}/`);
  }

  async createRequest(data) {
    return client.post(`${BASE_URL}/`, data);
  }

  async updateRequest(data, id) {
    return client.patch(`${BASE_URL}/${id}/`, data);
  }

  async archiveRequest(id, isArchived) {
    return client.patch(`${BASE_URL}/${id}/archive/`, { isArchived });
  }
  async createCategory(data) {
    return client.post(`${BASE_URL}/category/`, data);
  }

  async getCategoryById(id) {
    return client.get(`${BASE_URL}/category/${id}/`);
  }

  async updateCategory(data, id) {
    return client.put(`${BASE_URL}/category/${id}/`, data);
  }

  async archiveCategory(id, isArchived) {
    return client.patch(`${BASE_URL}/category/${id}/archive/`, { isArchived });
  }

  async createType(data) {
    return client.post(`${BASE_URL}/category-type/`, data);
  }

  async getTypeById(id) {
    return client.get(`${BASE_URL}/category-type/${id}/`);
  }

  async updateType(data, id) {
    return client.put(`${BASE_URL}/category-type/${id}/`, data);
  }

  async archiveType(id, isArchived) {
    return client.patch(`${BASE_URL}/category-type/${id}/archive/`, { isArchived });
  }

  async getTerms() {
    return client.get(`${BASE_URL}/terms/`);
  }

  async saveTerms(formData) {
    return client.put(`${BASE_URL}/terms/`, formData);
  }

  async deleteTerms() {
    return client.delete(`${BASE_URL}/terms/`);
  }

}

export default new RequestsService()