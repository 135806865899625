<template>
  <form-multi-select
    ref="multiselect"
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    :item-text="item => `${item.firstName} ${item.lastName}`"
    :can-select-all="canSelectAll"
    :all-message="$t('label.all_clients')"
    :item-value="item => item"
    :value-comparator="$options.compareById"
    :is-filtered="isFiltered"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchManyClients } from '@/services/select';

// Components
import FormMultiSelect from './FormMultiselect.vue';

export default {
  name: 'SchemaFormFieldManyClients',

  components: { FormMultiSelect },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      canSelectAll: false,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    isFiltered() {
      return Boolean(this.payload.projects) || Boolean(this.payload.buildings) || Boolean(this.payload.units);
    },
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.multiselect?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload, config) {
      const { projects, buildings, units, rooms } = this.payload;

      return fetchManyClients(
        {
          limit: 100,
          ...payload,
          ...this.payload,
          projects,
          buildings,
          units: rooms || units,
        },
        config
      ).then(({ results }) => {
        this.canSelectAll = !!results.length;
        return results;
      });
    },
  },

  compareById,
};
</script>
