<template>
  <v-checkbox class="base-checkbox" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'BaseCheckbox',
};
</script>

<style lang="scss">
.base-checkbox {
  .mdi-checkbox-blank-outline::before {
    color: rgba($--blue-color-20, 0.5);
  }
}
</style>
