<template>
  <FormAutocomplete
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    :item-text="item => getTranslatedText(item)"
    :item-value="item => item"
    :compare-values="$options.compareByValue"
    v-on="inheritedListeners"
  />
</template>

<script>
import { compareByValue } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

import { fetchCountries } from '@/services/select';
import FormAutocomplete from './FormAutocomplete.vue';

export default {
  compareByValue,

  name: 'SchemaFormFieldCountry',

  components: {
    FormAutocomplete,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  methods: {
    queryItems(payload) {
      return fetchCountries({ ...payload, ...this.payload, ...this.queryParams }).then(({ results }) =>
        results.sort((a, b) => {
          if (this.$t(a.name) < this.$t(b.name)) return -1;
          if (this.$t(a.name) > this.$t(b.name)) return 1;
          return 0;
        })
      );
    },

    getTranslatedText(item) {
      return this.$t(item.name);
    },
  },
};
</script>
