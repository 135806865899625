import * as schemaItemTypes from '@/schemas/schemaItemTypes';

export function getSchemaPropertyByPath(schema, path) {
  const propStack = [...path];
  let schemaProperty = schema;

  while (propStack.length > 0) {
    const propKey = propStack.shift();
    if (Array.isArray(schemaProperty)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of schemaProperty) {
        const isTargetProperty = item.prop === propKey;
        if (isTargetProperty) {
          schemaProperty = item;
          break;
        }

        const noEffectOnModel = schemaItemTypes.TYPES_WITHOUT_EFFECT.includes(item.type);

        const hasChildren = Array.isArray(item?.children);

        if (noEffectOnModel && hasChildren) {
          const targetProperty = getSchemaPropertyByPath(item.children, [propKey]);
          if (targetProperty) {
            schemaProperty = targetProperty;
            break;
          }
        }
      }
    } else {
      const isListItem = schemaProperty?.type === schemaItemTypes.LIST;
      const hasChildren = Array.isArray(schemaProperty?.children);
      if (isListItem && hasChildren) {
        schemaProperty = schemaProperty.children;
      }
    }
  }

  return schemaProperty === schema ? null : schemaProperty;
}

export function getPropertyByPath(data, path) {
  return path.reduce((pathValue, propKey) => {
    return pathValue?.[propKey] ?? null;
  }, data);
}
