class TimeService {
  constructor() {
    this.subscribers = [];

    setInterval(() => {
      this.subscribers.forEach(subscribe => subscribe(new Date()));
    }, 1000);
  }

  subscribe(cb) {
    this.subscribers.push(cb);
  }

  unsubscribe(cb) {
    this.subscribers = this.subscribers.filter(subscribe => subscribe !== cb);
  }
}

export default new TimeService();
