export default function convertSizeFile(size) {
  const fileSizeKb = size / 1024;

  const fileSizeMb = fileSizeKb / 1024;

  const fileSizeGb = fileSizeMb / 1024;

  const fileSizeTb = fileSizeGb / 1024;

  let fileSize = null;

  if (fileSizeKb <= 1024) {
    fileSize = `${fileSizeKb.toFixed(3)} KB`;
  } else if (fileSizeKb >= 1024 && fileSizeMb <= 1024) {
    fileSize = `${fileSizeMb.toFixed(1)} MB`;
  } else if (fileSizeMb >= 1024 && fileSizeGb <= 1024) {
    fileSize = `${fileSizeGb.toFixed(1)} GB`;
  } else {
    fileSize = `${fileSizeTb.toFixed(1)} TB`;
  }

  return fileSize;
}
