// eslint-disable-next-line import/prefer-default-export
export function evaluateBooleanExpressionTree(tree) {
  if (tree.type === 'Literal') {
    return tree.value;
  }

  if (tree.type === 'LogicalExpression') {
    if (tree.operator === '&&') {
      return evaluateBooleanExpressionTree(tree.left) && this.evaluateBooleanExpressionTree(tree.right);
    }

    return evaluateBooleanExpressionTree(tree.left) || this.evaluateBooleanExpressionTree(tree.right);
  }

  return null;
}
