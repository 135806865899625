<template>
  <div class="form-field-script-item">
    <FormItem
      v-for="child in filterSchema"
      :key="child.prop"
      v-bind="child"
      :prop-path="getPropertyPath(child, index)"
    />
  </div>
</template>

<script>
export default {
  name: 'FormFieldScriptItem',
  components: {
    FormItem: () => import('./FormItem.vue'),
  },
  inject: {
    computeDisplayConditions: 'computeDisplayConditions',
  },
  props: {
    schema: { type: Array, required: true },
    index: { type: Number, required: true },
    propPath: { type: Array, required: true },
  },
  computed: {
    filterSchema() {
      return this.schema.filter(({ scriptDisplayConditions }) => {
        if (scriptDisplayConditions) {
          return this.computeDisplayConditions(scriptDisplayConditions, [
            ...this.propPath,
            this.index,
            scriptDisplayConditions.expression,
          ]);
        }
        return true;
      });
    },
  },
  methods: {
    getPropertyPath(child, index) {
      return child.prop ? [...this.propPath, index, child.prop] : [...this.propPath, index];
    },
  },
};
</script>
