export function createModelWrapper(prop, event) {
  return {
    get() {
      return this[prop];
    },
    set(value) {
      this.$emit(event, value);
    },
  };
}

export function getInheritedListeners(excludedListenerKeys) {
  return {
    get() {
      return Object.fromEntries(
        Object.entries(this.$listeners).filter(([listenerKey]) => !excludedListenerKeys.includes(listenerKey))
      );
    },
  };
}
