/* eslint-disable */
import client from '@/http/client';
import i18n from '@/plugins/i18n';

// Utils
import { download, repackResponseFile } from '@/utils/common';

const BASE_URL = '/users';

export class UsersService {
  static usagesStatus = Object.freeze({
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    BOOKED: 'booked',
    CANCELED: 'canceled',
  });

  // old methods (sendMailAll = passwordResetMailAll, sendMail = passwordResetMail)
  sendMailAll({ clientType }) {
    return client.post(`${BASE_URL}/clients/registration-mail/all/`, { clientType });
  }
  /**
   * @param {object} param0
   * @param {boolean} param0.isArchived
   */
  sendMail({ clients }) {
    return client.post(`${BASE_URL}/clients/registration-mail/`, {
      clients,
    });
  }

  // new methods (sendMailAll = passwordResetMailAll, sendMail = passwordResetMail)
  passwordResetMailAll({ clientType }) {
    return client.post(`${BASE_URL}/clients/reset-password-mail/all/`, { clientType });
  }

  /**
   * @param {object} param0
   * @param {boolean} param0.isArchived
   */
  passwordResetMail({ clients }) {
    return client.post(`${BASE_URL}/clients/reset-password-mail/`, {
      clients,
    });
  }

  /**
   * @param {object} param0
   * @param {number[]} param0.usages
   * @param {boolean} param0.isArchived
   */
  archiveUsages({ usages, isArchived }) {
    return client.patch(`${BASE_URL}/clients/usages/archive/`, {
      usages,
      isArchived,
    });
  }

  archiveClients({ clients, isArchived }) {
    return client.patch(`${BASE_URL}/clients/archive/`, {
      clients,
      isArchived,
    });
  }

  /**
   * @param {object} param0
   * @param {boolean} param0.isArchived
   */
  archiveAllUsages({ isArchived }) {
    return client.patch(`${BASE_URL}/clients/usages/archive/all/`, {
      isArchived,
    });
  }

  archiveAllClients({ isArchived }) {
    return client.patch(`${BASE_URL}/clients/archive/all/`, {
      isArchived,
    });
  }

  archiveEmployees({ employees, isArchived }) {
    return client.patch(`${BASE_URL}/employees/archive/`, {
      employees,
      isArchived,
    });
  }

  archiveAllEmployees({ isArchived }) {
    return client.patch(`${BASE_URL}/employees/archive/all/`, {
      isArchived,
    });
  }

  getClients({ limit, offset, isArchived, search, orderBy } = {}) {
    return client.get(`${BASE_URL}/clients/`, {
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy,
      },
    });
  }

  getClientsOwner({ limit, offset, isArchived, search, orderBy, status } = {}, config) {
    return client.get(`${BASE_URL}/clients/owners/`, {
      ...config,
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy,
        status,
      },
    });
  }

  getClientsTenants({ limit, offset, isArchived, search, orderBy, status } = {}, config) {
    return client.get(`${BASE_URL}/clients/tenants/`, {
      ...config,
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy,
        status,
      },
    });
  }

  getClientsShortTenants(
    { limit, offset, isArchived, search, orderBy, status, rentalPeriodAfter, rentalPeriodBefore } = {},
    config
  ) {
    return client.get(`${BASE_URL}/clients/short_term_tenants/`, {
      ...config,
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy,
        status,
        rentalPeriodAfter,
        rentalPeriodBefore,
      },
    });
  }

  getEmployees({ limit, offset, search, orderBy, isArchived } = {}, config) {
    return client.get(`${BASE_URL}/employees/`, {
      ...config,
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy,
      },
    });
  }

  getUsages({ client: localClient, unit, status, limit, offset, search, orderBy, isArchived } = {}) {
    return client.get(`${BASE_URL}/clients/usages/`, {
      params: {
        limit,
        offset,
        isArchived,
        search,
        orderBy,
        client: localClient,
        unit,
        status,
      },
    });
  }

  /**
   * @param {object} param0
   * @param {'active' | 'unconfirmed' | 'archived'} param0.status
   * @param {string} param0.orderBy
   * @param {string} param0.search
   * @param {number} param0.limit
   * @param {number} param0.offset
   */
  getClientsUsages({ status, orderBy, search, limit, offset }, config) {
    return client.get(`${BASE_URL}/clients/usages/`, {
      ...config,
      params: {
        status,
        orderBy,
        search,
        limit,
        offset,
      },
    });
  }

  exportClients() {
    return client.getRaw(`${BASE_URL}/clients/xlsx/`, {
      responseType: 'blob',
    });
  }

  importShortTermTenantsClients(file) {
    return client.post(`${BASE_URL}/clients/short_term_tenants/xlsx/`, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  importClients(file) {
    return client.post(`${BASE_URL}/clients/xlsx/`, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  async downloadClients(fallbackFilename) {
    const response = await this.exportClients();

    const { filename, blob } = repackResponseFile(response, fallbackFilename);

    download(filename, blob);
  }

  getImportStatus() {
    return client.get(`${BASE_URL}/clients/xlsx/status/`, {
      params: {
        // Disable cache
        timestamp: Date.now(),
      },
    });
  }

  getImportStatusShortTenants() {
    return client.get(`${BASE_URL}/clients/short_term_tenants/xlsx/status/`, {
      params: {
        // Disable cache
        timestamp: Date.now(),
      },
    });
  }

  addClient(requestBody) {
    return client.post(`${BASE_URL}/clients/`, requestBody);
  }

  addEmployee({ firstName, lastName, email, phone, roles }) {
    return client.post(`${BASE_URL}/employees/`, {
      firstName,
      lastName,
      email,
      phone,
      roles,
    });
  }

  getClientById(clientId) {
    return client.get(`${BASE_URL}/clients/${clientId}/`);
  }

  getEmployeeById(employeeId) {
    return client.get(`${BASE_URL}/employees/${employeeId}/`);
  }

  updateClientById(requestBody, clientId) {
    return client.put(`${BASE_URL}/clients/${clientId}/`, requestBody);
  }

  partialUpdateClientById(requestBody, clientId) {
    return client.patch(`${BASE_URL}/clients/${clientId}/`, requestBody);
  }

  updateEmployeeById({ employeeId, firstName, lastName, email, phone, roles }) {
    return client.put(`${BASE_URL}/employees/${employeeId}/`, {
      firstName,
      lastName,
      email,
      phone,
      roles,
    });
  }

  archiveClientById(clientId, isArchived) {
    return client.patch(`${BASE_URL}/clients/${clientId}/archive/`, {
      isArchived,
    });
  }

  normalizeUsage(usage) {
    return {
      ...usage,
      isPeriod: !Boolean(usage.salesContractDate),
      rentalPeriod: `${usage.rentalPeriodStartDate} - ${usage.rentalPeriodEndDate}`,
      originalClientType: usage.clientType,
      clientType: i18n.t(`client.${usage.clientType}`),
    };
  }

  getUsageById({ id }) {
    return client.get(`${BASE_URL}/clients/usages/${id}/`).then(this.normalizeUsage);
  }

  normalizeUpdateUsageParams(usage) {
    const rentalPeriodDates = usage.rentalPeriod.split(' - ');
    const rentalPeriodStartDate = rentalPeriodDates[0] === 'null' ? null : rentalPeriodDates[0];
    const rentalPeriodEndDate = rentalPeriodDates[1] === 'null' ? null : rentalPeriodDates[1];

    return {
      clientType: usage.clientType,
      unit: usage.room?.id || usage.unit.id,
      rentalPeriodStartDate,
      rentalPeriodEndDate,
      salesContractDate: usage.salesContractDate,
    };
  }

  updateUsageById(usage, usageId) {
    return client.put(
      `${BASE_URL}/clients/usages/${usageId}/`, this.normalizeUpdateUsageParams(usage));
  }

  createRole({name, accessLevels}) {
    return client.post(`${BASE_URL}/roles/`, { name, accessLevels });
  }

  updateRoleById({name, accessLevels, roleId}) {
    return client.put(`${BASE_URL}/roles/${roleId}/`, { name, accessLevels });
  }

  getRoleById(roleId) {
    return client.get(`${BASE_URL}/roles/${roleId}/`);
  }

  getRoles({ limit, offset, search, orderBy } = {}, config) {
    return client.get(`${BASE_URL}/roles/`, {
      ...config,
      params: {
        limit,
        offset,
        search,
        orderBy,
      },
    });
    
  }

  deleteRole(roleId) {
    return client.delete(`${BASE_URL}/roles/${roleId}/`);
  }

  getDocuments(usageId) {
    return client.get(`${BASE_URL}/clients/usages/${usageId}/documents/`);
  }

  saveDocuments(bodyRequest, usageId) {
    return client.patch(`${BASE_URL}/clients/usages/${usageId}/documents/`, bodyRequest);
  }
}

export default new UsersService();
