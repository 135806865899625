/* eslint-disable class-methods-use-this */

import client from '@/http/client';
import { createObjectURL } from '@/utils/url';
import getUrlFromBlob from '@/utils/getUrlFromBlob';

const BASE_URL = 'background-media';

class MediaService {
  uploadImage(image) {
    const formData = new FormData();
    formData.append('image', image);
    return client.post(`${BASE_URL}/background-image/`, formData);
  }

  uploadFile(file, room, config) {
    const formData = new FormData();
    if (room) {
      formData.append('room', room);
    }
    formData.append('file', file);
    return client.post(`${BASE_URL}/background-file/`, formData, config);
  }

  async getPrivateMedia(url, config) {
    const newUrl =
      process.env.NODE_ENV === 'production' ? url : `http://testuk.${process.env.VUE_APP_BACKEND_API_URL}${url}`;

    const mediaBlob = await client.get(newUrl, {
      responseType: 'blob',
      ...config,
    });

    return createObjectURL(mediaBlob);
  }

  async getPrivateMediaObject(fileName, config) {
    const blob = await this.getPrivateMediaBlob(fileName, config);
    const url = await getUrlFromBlob(blob);

    return { url, blob };
  }

  getPrivateMediaBlob(url, config) {
    const newUrl =
      process.env.NODE_ENV === 'production' ? url : `http://testuk.${process.env.VUE_APP_BACKEND_API_URL}${url}`;

    return client.get(newUrl, {
      ...config,
      responseType: 'blob',
      params: {
        // Disable cache
        ...(config?.cache ? undefined : { timestamp: Date.now() }),
      },
      ignoreError: true,
    });
  }
}

export default new MediaService();
