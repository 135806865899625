<template>
  <label>
    <slot :on="on" :attrs="attrs"></slot>
    <input v-show="false" ref="uploader" type="file" :multiple="multiple" v-bind="$attrs" @change="change" />
  </label>
</template>

<script>
export default {
  name: 'FileInput',

  props: {
    multiple: { type: Boolean, default: false },
  },

  data() {
    return {
      on: {
        click: () => {
          this.$refs.uploader.click();
          this.isWaiting = true;

          window.addEventListener(
            'focus',
            () => {
              this.isWaiting = false;
            },
            { once: true }
          );
        },
      },

      isWaiting: false,
    };
  },

  computed: {
    attrs() {
      return {
        loading: this.isWaiting,
      };
    },
  },

  methods: {
    change(event) {
      this.$emit('input', event.target.files);
      // fix: разрешить повторно прикреплять один и тот же файл в подряд
      this.$refs.uploader.value = '';
    },
  },
};
</script>
